import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2740 1996 c560 -67 1081 -388 1396 -861 149 -225 243 -450 299 -716
33 -155 45 -431 26 -597 -60 -521 -323 -997 -732 -1325 -76 -62 -212 -157
-223 -157 -2 0 -35 -17 -73 -39 -116 -66 -319 -146 -453 -179 -338 -84 -742
-72 -1070 33 -515 163 -929 516 -1174 1002 -154 305 -212 572 -203 931 7 277
39 439 134 682 53 134 191 377 265 465 9 11 33 40 53 65 167 205 422 404 661
515 214 99 474 173 664 188 112 9 325 5 430 -7z"/>
<path d="M2127 3683 c-8 -13 -6 -263 2 -263 3 0 42 36 86 80 l80 80 402 0 403
0 2 -692 3 -692 73 -37 c102 -52 201 -73 306 -66 244 17 465 171 576 402 60
125 75 190 74 340 0 119 -3 138 -32 225 -107 319 -331 521 -667 602 -86 21
-116 22 -697 25 -333 2 -608 0 -611 -4z"/>
<path d="M1160 2750 l0 -830 413 0 414 0 2 57 c1 31 -2 59 -6 62 -16 11 -123
163 -123 175 0 6 59 -49 130 -121 l130 -133 0 721 0 722 -87 88 -87 89 -393 0
-393 0 0 -830z"/>
<path d="M2512 3125 c-67 -16 -140 -53 -153 -77 -9 -16 -4 -15 28 6 112 73
262 85 378 30 130 -61 122 -59 102 -37 -27 31 -122 73 -185 84 -72 11 -104 10
-170 -6z"/>
<path d="M2560 3093 c-49 -8 -57 -13 -37 -24 13 -7 22 -7 32 1 8 7 27 9 51 4
29 -5 36 -4 32 7 -5 15 -36 20 -78 12z"/>
<path d="M2445 3040 c28 -12 37 -12 30 0 -3 6 -16 10 -28 9 -21 0 -21 -1 -2
-9z"/>
<path d="M2814 3011 c-5 -3 -15 -18 -21 -33 -8 -18 -20 -28 -35 -29 -15 0 -5
-7 27 -18 121 -43 209 -104 219 -152 3 -18 2 -37 -4 -43 -7 -7 -10 -3 -10 12
0 44 -99 118 -202 151 -62 20 -80 8 -25 -17 26 -13 52 -20 57 -17 6 3 10 1 10
-4 0 -6 7 -11 16 -11 9 0 14 -5 12 -12 -6 -16 -69 -17 -94 -1 -30 19 -66 8
-99 -29 -60 -67 -34 -158 46 -158 33 0 40 -4 45 -25 5 -21 12 -25 44 -25 20 0
51 -5 69 -11 29 -10 34 -9 71 29 22 22 40 45 40 51 0 6 5 11 10 11 15 0 12
-14 -7 -36 -24 -26 -66 -104 -57 -104 4 0 17 8 28 18 20 16 20 16 7 -1 -8 -9
-11 -20 -8 -24 4 -3 2 -14 -4 -24 -5 -11 -6 -19 -1 -19 15 0 60 103 72 165 14
75 1 163 -35 243 -27 60 -72 115 -86 106 -5 -3 -24 -1 -43 4 -18 5 -38 7 -42
3z"/>
<path d="M2748 3003 c-24 -6 -23 -19 1 -26 22 -5 31 0 31 20 0 13 -3 14 -32 6z"/>
<path d="M2220 2870 c-9 -21 -10 -34 -3 -41 7 -7 13 1 17 26 4 20 5 39 3 41
-2 3 -10 -9 -17 -26z"/>
<path d="M2252 2835 c-7 -14 -12 -32 -12 -40 0 -18 17 -20 22 -3 3 7 21 12 41
12 42 0 48 12 13 26 -13 5 -27 14 -31 19 -10 18 -21 13 -33 -14z"/>
<path d="M2195 2759 c-4 -20 -1 -64 5 -100 13 -71 59 -182 67 -160 3 7 -4 30
-15 50 -27 49 -45 135 -39 181 6 50 -9 76 -18 29z"/>
<path d="M2449 2645 c-1 -3 -2 -8 -3 -12 -1 -5 -5 -13 -9 -20 -7 -12 66 -63
91 -63 32 0 22 45 -11 54 -13 3 -32 15 -45 26 -12 11 -23 18 -23 15z"/>
<path d="M2390 2564 c-7 -8 -19 -14 -26 -14 -16 0 -19 -26 -4 -35 6 -3 10 -13
10 -21 0 -11 -4 -12 -19 -4 -11 5 -22 10 -26 10 -9 0 28 -53 50 -72 11 -9 18
-19 15 -22 -3 -3 -26 12 -50 34 -53 46 -69 51 -45 13 26 -41 49 -60 111 -94
43 -24 64 -30 92 -26 80 10 140 31 177 61 19 16 19 16 1 30 -10 8 -15 19 -12
24 7 11 -11 20 -71 37 -32 9 -40 9 -33 1 6 -8 3 -12 -12 -13 -15 -2 -19 3 -15
17 4 15 -7 26 -47 48 -71 40 -83 43 -96 26z"/>
<path d="M4100 2468 c-91 -182 -227 -303 -416 -371 -186 -67 -393 -57 -527 24
-26 16 -49 29 -52 29 -3 0 -5 -61 -5 -135 l0 -135 -425 0 -425 0 0 61 c0 62 5
73 88 174 25 31 43 65 34 65 -6 0 -15 -7 -19 -15 -5 -8 -58 -64 -118 -125
l-109 -111 -1 -107 0 -107 625 0 c615 0 626 0 706 22 248 69 441 204 567 398
67 105 121 246 133 350 10 82 -10 75 -56 -17z"/>
<path d="M2684 2361 c-17 -10 -57 -27 -90 -37 l-59 -19 53 -3 c63 -4 151 13
178 33 18 13 16 14 -18 15 -37 0 -51 13 -25 23 6 3 8 6 2 6 -5 1 -24 -8 -41
-18z"/>
<path d="M1360 1431 c0 -151 1 -160 20 -166 11 -3 29 -3 40 0 18 6 20 15 20
123 0 133 4 133 51 -6 39 -117 61 -141 101 -109 8 6 33 60 54 119 21 59 40
105 43 103 2 -3 5 -53 6 -110 1 -111 8 -129 46 -123 l24 3 0 160 0 160 -43 3
c-66 5 -83 -12 -116 -114 -16 -49 -32 -93 -36 -97 -4 -5 -22 35 -41 89 -18 53
-39 103 -47 110 -8 9 -35 14 -68 14 l-54 0 0 -159z"/>
<path d="M1826 1582 c-2 -4 23 -50 56 -101 42 -65 62 -105 63 -130 1 -20 3
-46 4 -58 1 -24 28 -37 55 -27 12 5 16 19 16 59 0 44 6 62 35 105 19 29 35 54
35 57 0 2 11 19 24 36 12 18 26 40 29 50 6 16 2 18 -25 15 -32 -3 -42 -13
-114 -112 l-20 -28 -35 53 c-18 30 -43 62 -54 71 -21 18 -60 24 -69 10z"/>
<path d="M2207 1583 c-4 -3 -7 -75 -7 -159 l0 -154 26 -6 c33 -9 180 3 220 18
50 18 79 70 79 143 0 87 -29 128 -105 149 -56 16 -201 22 -213 9z m224 -82
c26 -31 26 -120 1 -151 -14 -17 -32 -23 -85 -27 l-67 -6 0 108 0 107 66 -4
c55 -3 70 -8 85 -27z"/>
<path d="M2699 1574 c-28 -8 -53 -24 -63 -39 -27 -41 -81 -254 -67 -268 20
-20 64 4 75 41 l9 32 78 0 c95 0 94 0 95 -25 1 -37 4 -43 20 -49 9 -4 25 -4
35 -1 17 6 19 19 19 160 0 130 -2 154 -16 159 -28 11 -138 5 -185 -10z m123
-101 c0 -29 -1 -57 -1 -63 -1 -12 -138 -14 -145 -2 -9 15 14 82 35 103 15 15
31 19 66 17 l46 -3 -1 -52z"/>
<path d="M2954 1575 c-13 -33 4 -45 61 -45 l55 0 0 -129 c0 -140 3 -148 56
-135 24 6 24 7 24 135 l0 129 60 0 c64 0 85 15 60 45 -18 22 -308 22 -316 0z"/>
<path d="M3462 1580 c-87 -13 -106 -44 -148 -235 -18 -83 -18 -85 16 -85 31 0
50 22 50 59 0 20 6 21 96 21 64 0 93 -3 89 -11 -10 -15 3 -56 19 -62 8 -3 24
-3 35 0 20 5 21 12 21 148 0 79 -3 150 -6 159 -7 18 -75 20 -172 6z m98 -115
l0 -65 -75 0 c-82 0 -84 1 -64 61 19 56 36 69 91 69 l48 0 0 -65z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
